import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import Menu from 'src/layouts/MainLayout/Menu';
import Button from '@material-ui/core/Button';
import SearchFilters from 'src/components/AnageImoveis/SearchFilters';
import 'src/theme/index.css';

import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';

import searchIcon from 'src/assets/images/icons/feather-search.svg';
import { Link } from '@material-ui/core';

import FavoriteModal from 'src/components/AnageImoveis/FavoriteModal.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ManagerApi from 'src/services/managerApi';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal, setRealEstateFilters } from 'src/actions';
import { useHistory } from 'react-router';
import slugify from 'slugify';

const detectMob = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

function Header({ filters, suggestion }) {
    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const dispatch = useDispatch();
    const history = useHistory();

    const [showFilter, setShowFilter] = useState(false);

    return (
        <>
            <div id="main-header" className="header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <RouterLink to="/" className="navbar-brand">
                            {/* <span className="creci-header">
                                CRECI 1216-J
                            </span> */}
                            <Logo />
                        </RouterLink>
                        {/* <RouterLink to="/comprar" className="btn filter-button">
                            <img src={searchIcon} />
                            Buscar imóveis
                        </RouterLink> */}
                        <Button onClick={() => setShowFilter(!showFilter)} className="btn filter-button">
                            <img src={searchIcon} />
                            Buscar imóveis
                        </Button>
                        <div className='collapse navbar-collapse' id="navbarSupportedContent">
                            <div className="search-block ml-auto">
                                <Formik
                                    initialValues={{namecode: ''}}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values, { resetForm, setStatus, setFieldValue }) => {
                                      history.push('/comprar' + '?namecode=' + encodeURI(values.namecode));
                                      dispatch(setRealEstateFilters({
                                        namecode: values.namecode,
                                        tagSelect: 1,
                                        status: 'comprar'
                                      }));
                                      resetForm();
                                    }}
                                    >
                                    {({ values, setFieldValue, handleBlur, handleChange }) => {
                                      return (
                                        <FormikForm className="form">
                                            <div className="form-group normal-group">
                                                <input type="text" 
                                                    className="form-control" 
                                                    placeholder="Buscar por código ou nome do empreendimento"
                                                    name="namecode" value={values.namecode} onBlur={handleBlur} onChange={handleChange}
                                                    />
                                                <button type="submit" className="btn btn-ok">ok</button>
                                            </div>
                                        </FormikForm>
                                      )
                                    }}
                                </Formik>
                            </div>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link
                                        href="https://www.anageimoveis.com.br/anuncie-seu-imovel"
                                        className="nav-link"
                                        target="_blank"
                                    >
                                        Deixar meu imóvel para venda
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <Menu />
                    </div>
                </nav>
            </div>
            {showFilter && (
              <>
                <SearchFilters onFilter={() => {setShowFilter(false) }}/>
                <div className="back-filter" onClick={() => {setShowFilter(false) }}></div>
              </>
            )}
        </>
    );
}

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
