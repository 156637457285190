import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import video from 'src/assets/images/icons/awesome-video.svg';
import car from 'src/assets/images/icons/car.svg';
import area from 'src/assets/images/icons/icon-area.svg';
import bed from 'src/assets/images/icons/bed.svg';
import like from 'src/assets/images/icons/like-1.svg';
import likeFilled from 'src/assets/images/icons/like-filled.png';
import Dialog from '@material-ui/core/Dialog';

import SwiperCore, { Virtual, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatPrice, getYoutubeEmbedData } from 'src/utils/functions.js';

import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, removeFavorite } from 'src/actions';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual]);

export default function CardProperty({realEstate, gallery = true}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const interfaceStore = useSelector((state) => state.interface);
    const dispatch = useDispatch();
    
    const [swiperInstance, setSwiperInstance] = useState(null);

    if(!realEstate) {
        return null;
    }
    
    let images = realEstate.gallery;
    let realEstateStatus = realEstate.status.charAt(0).toUpperCase() + realEstate.status.slice(1);
    let realEstateOrigin = realEstate.origin;
    let realEstateExchange = realEstate.exchange;

    const isFavorite = (id) => {
        let found = false;
        for (let i = 0; i < interfaceStore.favorites.length; i++) {
            if (interfaceStore.favorites[i] === id) {
                found = true;
            }
        }
        return found;
    }

    let savedSearch = "";
    if (sessionStorage.getItem('savedSearch') !== null) {
        savedSearch = sessionStorage.getItem('savedSearch');
    }

    return (
        <div className="card card-property">
            <div className="row">
                <div className="col-md-6">
                    <RouterLink to={`/${realEstate.slug+savedSearch}`} target="_blank">
                        <div className="property-images">
                            {gallery ? (
                                <>
                                    <Swiper
                                        slidesPerView={1}
                                        onSwiper={(swiper) => { setTimeout( () => {swiper.update();}, 200); setSwiperInstance(swiper) } }
                                        virtual
                                    >
                                        {images.map( (image, index) => (
                                            <SwiperSlide key={index} virtualIndex={index}>
                                                <div className="image" style={{ backgroundImage: 'url(' + image.image + ')' }}></div>
                                            </SwiperSlide>
                                        ))}
                                        <div className="swiper-button-prev" onClick={ (e) => { e.preventDefault(); swiperInstance.slidePrev() }}></div>
                                        <div className="swiper-button-next"onClick={ (e) => { e.preventDefault(); swiperInstance.slideNext() }}></div>
                                    </Swiper>
                                </>
                            ) : (
                                <>
                                    <div className="swiper-slide">
                                        <div className="image" style={{ backgroundImage: 'url(' + images[0].image + ')' }}></div>
                                    </div>
                                </>

                            )}
                        </div>
                    </RouterLink>
                    <div className="tags">
                        {realEstateExchange && (
                            <div className="tag yellow">Permuta</div>
                        )}
                        {realEstateOrigin && (
                            <div className="tag yellow">{realEstateOrigin}</div>
                        )}                        
                    </div>                    
                </div>
                <div className="col-md-6">
                    <div className="description">
                        <div className="info top-info">
                            <RouterLink to={`/${realEstate.slug+savedSearch}`} target="_blank">
                                <h2 className="block-title">
                                    <span className="title-small">{realEstate.category && realEstate.category.name}</span>
                                    <span className="title title-9">{realEstate.neighbourhood} - {realEstate.city}</span>
                                </h2>
                            </RouterLink>
                            <div className="icons hidden-mobile">
                                { isFavorite(realEstate.id) ? (
                                    <img alt="" src={likeFilled} className="icon filled" onClick={ () => { dispatch(removeFavorite(realEstate.id)) }} />
                                ) : (
                                    <img alt="" src={like} className="icon linear" onClick={ () => { dispatch(addFavorite(realEstate.id)) }} />
                                )}
                                <p className="text text-2 ref">Ref.: {realEstate.code}</p>
                            </div>
                        </div>
                        <div className="info middle-info">
                            <div className="feature">
                                {realEstate.bedrooms !== '0' && (
                                    <>
                                        <img alt="" src={bed} className="icon"></img>
                                        <span className="info-feature">{realEstate.bedrooms} quartos</span>
                                    </>
                                )}
                            </div>
                            <div className="feature">
                                <img alt="" src={area} className="icon"></img>
                                <span className="info-feature">{Number.parseFloat(realEstate.area).toFixed(0)}m²</span>
                            </div>
                            <div className="feature">
                                {realEstate.parkingSpaces !== '0' && (
                                    <>
                                        <img alt="" src={car} className="icon"></img>
                                        <span className="info-feature">{realEstate.parkingSpaces} vagas</span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="info">
                            <p className="text text-5" dangerouslySetInnerHTML={{ __html: realEstate.description }}></p>
                        </div>
                        <div className="info bottom-info">
                            <div>
                                <p className="text text-2">Venda:</p>
                                
                                <div>
                                    {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                        <>
                                            <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                            <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                        </>
                                    )}
                                </div>

                                {realEstate.video !== '' && (
                                    <>
                                        <a href="#" className="btn btn-2" onClick={handleClickOpen}>
                                            <img src={video} className="icon" />
                                            Vídeo
                                        </a>
                                        <Dialog 
                                            open={open}
                                            onClose={handleClose}
                                            >
                                            <iframe
                                                src={getYoutubeEmbedData(realEstate.video).url}
                                                frameBorder="0"
                                                allow="autoplay; encrypted-media"
                                                allowFullScreen
                                                style={{
                                                    width: "100%",
                                                    height: "400px"
                                                }}
                                            />
                                        </Dialog>
                                    </>
                                )}
                            </div>
                            <div className="icons show-mobile">
                                { isFavorite(realEstate.id) ? (
                                    <img alt="" src={likeFilled} className="icon filled" onClick={ () => { dispatch(removeFavorite(realEstate.id)) }} />
                                ) : (
                                    <img alt="" src={like} className="icon linear" onClick={ () => { dispatch(addFavorite(realEstate.id)) }} />
                                )}
                                <p className="text text-2 ref">Ref.: {realEstate.code}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
